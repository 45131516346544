import { apiKey } from '../config';
import { logout } from './operations/sessionOperations';
import cookie from "js-cookie";

const customFetch = async (
    url,
    method,
    params,
    rawResponse = false,
) => {
    let body = undefined;
    if (params && method !== "GET") {
        body = JSON.stringify(params);
    }
    const options = {
        method,
        body,
        headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            'x-api-key': apiKey,
            ...await authHeader()
        }
    };
    try{
        const response = await fetch(url, options);
        return rawResponse? response : handleResponse(response);
    } catch (e){
        console.log(e);
        return handleError(e);
    }
}

const get = (url, params) => {
    const response = customFetch(url, "GET", params);
    return response;
};

const post = (url, params, rawResponse = false) => {
    const response = customFetch(url, "POST", params, rawResponse);
    return response;
};

const put = (url, params) => {
    const response = customFetch(url, "PUT", params);
    return response;
};

const del = (url, params) => {
    const response = customFetch(url, "DELETE", params);
    return response;
};

const authHeader = async () => {
    const accessToken = cookie.get("access_token");
    if (accessToken) {
        return { Authorization: `Bearer ${accessToken}` };
    } else {
        logout();
    }
}

const handleResponse = (response) => {
    return response.text().then(text => {
        let data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status == 401){
                logout()
                return data
            }
            return handleError(data || {message: 'Error al procesar tu solicitud.'});
        }
        return data;
    });
}

export const handleError = (error) => {
    let customError = new Error(error.code ? error.message : 'Error al procesar tu solicitud.');
    customError.name = error.code || "badly_processed_request";
    return Promise.reject(customError);
}

export default {
    get,
    post,
    put,
    delete: del
};