import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from '../../../hooks/Nav';
import useFormatDate from '../../../hooks/useFormatDate';
import Sort from '../../../tailwindUI/Sort';
import SearchBar from '../../../tailwindUI/SearchBar';
import EmptyState from '../../../tailwindUI/EmptyState';
import Table from '../../../tailwindUI/Table';
import { AdjustmentsHorizontalIcon, DocumentArrowDownIcon, DocumentMagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Pagination from '../../../tailwindUI/Pagination';
import usePagination from '../../../tailwindUI/usePagination';
import useSort from '../../../tailwindUI/useSort';
import LongAlert from '../../../tailwindUI/LongAlert';
import useScrollPosition from '../../../hooks/useScrollPosition';
import AccentButton from '../../../tailwindUI/AccentButton';
import { getStages } from '../../../apiClient/operations/stagesOperations';
import CircleIconCredentianls from '../../../hooks/CircleIconCredentials';
import useDateInterval from '../../../tailwindUI/useDateInterval';
import SlideOver from '../../../tailwindUI/SlideOver';
import DateIntervalV2 from '../../../tailwindUI/DateIntervalV2';
import { CSVLink } from 'react-csv';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import useFormatPhoneNumber from '../../../hooks/useFormatPhoneNumber';
import StatusMenuButton from '../../../tailwindUI/StatusMenuButton';
import { ModalNotification } from '../../modals/ModalNotification';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import { updateAccountStatus } from '../../../apiClient/operations/accountsOperations';
import { AuthContext } from '../../../cognito/AuthProvider';

const tableColumns = [
    { heading: 'Fecha', value: 'created_formatted' },
    { heading: 'Contribuyente', value: 'name', subvalue: 'tax_id', main: true },
    { heading: 'Estatus', value: 'status' },
    { heading: 'Tipo persona', value: 'legal_type_formatted' },
    { heading: 'CIEC', value: 'sat_ciec', align: 'center' },
    { heading: 'eFirma', value: 'sat_efirma', align: 'center' },
    { heading: 'CSF', value: 'tax_status', align: 'center' },
    { heading: '32D', value: 'tax_compliance', align: 'center' }
];
const itemsLimitInTable = 10;

const headers = [
    'Fecha',
    'Nombre',
    'RFC',
    'Email',
    'Teléfono',
    'CIEC',
    "efirma",
    "CSF",
    "32D"
]
const castStatus = {
    "active": 'Activo',
    "invalid": "Inválido",
    "expired": "Expirado",
    "pending": "Pendiente",
    "none": "No tiene",
    "": "No tiene",
    "positive": "Positivo",
    "negative": "Negativo",
    "NO_OBLIGATIONS": "Sin obligaciones",
    "invalid_provider": "Inválido por el proveedor"
}

const modalInfo = {
    "confirm": {
        title: "¿Estás seguro de modificar el estatus?",
        description: ""
    },
    "success": {
        title: "¡Actualización exitosa!",
        description: "El cambio de estatus se hizo de manera correcta."
    },
    error: {
        title: "¡Error!",
        description: "No se pudieron actualizar los datos. Por favor, vuelve a intentarlo."
    }
}

function Taxpayers() {
    const { user } = useContext(AuthContext);
    const paginate = usePagination();
    const { formatDate } = useFormatDate();
    const { formatPhoneNumber } = useFormatPhoneNumber();
    const sortItem = useSort();
    const setScrollPosition = useScrollPosition();
    const history = useHistory();
    const [searchInput, setSearchInput] = useState(null);
    const [sortString, setSortString] = useState('');
    const [pagination, setPagination] = useState(null);
    const [currentPage, setCurrenPage] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const dateInterval = useDateInterval();
    const [filterString, setFilterString] = useState('');
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [openSlide, setOpenSlide] = useState(false);
    const [dataCsv, setDataCsv] = useState([]);
    const [loadingCSV, setLoadingCSV] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [statusModal, setStatusModal] = useState("confirm")
    const [itemToUpdate, setItemToUpdate] = useState(null)
    const [isPending, setIsPending] = useState(false)

    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false },
        { id: 3, name: 'Nombre descendente', field: 'name', sort: 'direct', active: false },
        { id: 4, name: 'Nombre ascendente', field: 'name', sort: 'reverse', active: false }
    ]);
    const [intervalsFilter, setIntervalsFilter] = useState(
        {
            id: 1,
            name: 'Fecha de actualización',
            value: 'updated',
            open: false
        }
    );

    useEffect(() => {
        if (searchInput == '' || searchInput == null) {
            handleGetAccounts(currentPage, itemsLimitInTable, searchInput, sortString, filterString);
        } else {
            setCurrenPage(1)
            handleGetAccounts(1, itemsLimitInTable, searchInput, sortString, filterString);
        }

    }, [searchInput]);

    useEffect(() => {
        if (filterString == '' || filterString == null) {
            handleGetAccounts(currentPage, itemsLimitInTable, searchInput, sortString, filterString);
        } else {
            setCurrenPage(1)
            handleGetAccounts(1, itemsLimitInTable, searchInput, sortString, filterString);
        }

    }, [filterString]);

    useEffect(() => {
        handleGetAccounts(currentPage, itemsLimitInTable, searchInput, sortString, filterString);
    }, [currentPage, sortString]);

    useEffect(() => {
        createCSV(searchInput, sortString, filterString)
    }, [filterString, sortString, searchInput]);

    useEffect(() => {
        const activeOptions = sortOptions?.filter(option => option.active);
        if (activeOptions.length > 0) {
            const sortedItem = sortItem(activeOptions[0]);
            setSortString(sortedItem);
        }
    }, [sortOptions]);

    const handleApplyFilters = () => {
        setFilterString(dateInterval(intervalsFilter));
        setFiltersApplied(true);
    }

    useEffect(() => {
        filtersApplied && handleApplyFilters();
    }, [filtersApplied, intervalsFilter]);

    useEffect(() => {
        filtersApplied == false && setFilterString('')
    }, [filtersApplied]);

    const handleUpdateStatus = ({newStatus, currentStatus, id}) => {
        if (currentStatus === newStatus) {
            return
        }
        setStatusModal("confirm")
        setShowModal(true);
        setItemToUpdate({id, newStatus }) 
    }

    const updateAccountStatusFn = async () => {
        try {
            setIsPending(true)
            const res = await updateAccountStatus(itemToUpdate?.id, itemToUpdate?.newStatus, user);
            setAccounts(accounts.map(item => {
                if (item.id === itemToUpdate.id) {
                    return {
                        ...item,
                        status: <StatusMenuButton status={res.status} updateStatus={(newStatus) => handleUpdateStatus({ newStatus, currentStatus: res.status, id: res.id})}  />, 
                    }
                }
                return item
            }))
            setStatusModal("success")
        } catch (e) {
            setStatusModal("error")
        } finally {
            setIsPending(false)
        }
    }

    const handleGetAccounts = async (page, limit, search, sort, filters) => {
        setIsLoading(true);
        try {
            const res = await getStages(page, limit, search, sort, ['taxpayer', 'customer'], filters);
            // const res = await getAccounts(page, limit, search, sort, ['taxpayer', 'customer']);
            setPagination(paginate(res.total_items, itemsLimitInTable, Math.ceil(res.total_items / res.limit)));
            const response = res.data.prospects.map(item => {
                return {
                    ...item,
                    id: item.account.id,
                    name: item.account.name,
                    tax_id: item.account.taxpayer.tax_id,
                    status: <StatusMenuButton status={item.account.status} updateStatus={(newStatus) => handleUpdateStatus({ newStatus, currentStatus: item.account.status, id: item.account.id })}  />,
                    created_formatted: formatDate(item.updated, 'DD MMMM YYYY'),
                    sat_ciec: item?.stage == 'taxpayer' ? <CircleIconCredentianls item={item.checklist.taxpayer} name="sat_ciec" /> : <CircleIconCredentianls item={item.checklist.customer} name="sat_ciec"/>,
                    sat_efirma: item?.stage == 'taxpayer' ? <CircleIconCredentianls item={item.checklist.taxpayer} name="sat_efirma" /> : <CircleIconCredentianls item={item.checklist.customer} name="sat_efirma" />, 
                    tax_status: item?.stage == 'taxpayer' ? <CircleIconCredentianls item={item.checklist.taxpayer} name="tax_status" /> : <CircleIconCredentianls item={item.checklist.customer} name="tax_status" />,
                    tax_compliance: item?.stage == 'taxpayer' ? <CircleIconCredentianls item={item.checklist.taxpayer} name="tax_compliance" /> : <CircleIconCredentianls item={item.checklist.customer} name="tax_compliance" />,
                    legal_type_formatted: item.account?.legal_type && (item.account.legal_type == 'personal' ? 'Persona Física' : 'Persona Moral') || ''
                }
            });
            setAccounts(response);
            setIsLoading(false);
        } catch (e) {
            setAccounts([]);
            setIsLoading(false);
            setError('No se pudieron obtener los contribuyentes');
        }
    }

    const createCSV = async (search, sort, filters) => {
        try {
            setLoadingCSV(true)
            let prospects = []
            const response = await getStages(1, 999, search, sort, ['taxpayer', 'customer'], filters)
            const totalPages = response.total_pages
            prospects.push(...response.data.prospects)

            if (totalPages > 1) {
                for (let page = 2; page <= totalPages; page++) {
                    const res = await getStages(page, 999, search, sort, ['taxpayer', 'customer'], filters)
                    prospects.push(...res.data.prospects)
                }
            }
            let rows = []
            prospects.map((item, index) => {
                const row = [
                    formatDate(item.created, 'DD MMMM YYYY'),
                    item.account.name,
                    item.account.taxpayer.tax_id,
                    item.account.email,
                    formatPhoneNumber(item.account.phone_number),
                    item?.stage == 'taxpayer' ? castStatus[item.checklist?.taxpayer[0]?.status] : castStatus[item.checklist?.customer[0]?.status],
                    item?.stage == 'taxpayer' ? castStatus[item.checklist?.taxpayer[1]?.status] : castStatus[item.checklist?.customer[1]?.status],
                    item?.stage == 'taxpayer' ? castStatus[item.checklist?.taxpayer[2]?.status] : castStatus[item.checklist?.customer[2]?.status],
                    item?.stage == 'taxpayer' ? castStatus[item.checklist?.taxpayer[3]?.status] : castStatus[item.checklist?.customer[3]?.status],
                ]
                rows.push(row)
                index == prospects.length - 1 && setLoadingCSV(false)
            })
            setDataCsv(rows)
        } catch (error) {
            console.log(error)
        }
    }

    const handleView = item => {
        setScrollPosition(0);
        // delete item.has_connections;
        history.push({
            pathname: `taxpayers/view/${item.id}#info`,
            // state: {
            //     account: item
            // }
        })
    }

    const columnActions = [
        {
            id: 1,
            name: 'Visualizar',
            type: 'primary',
            icon: <DocumentMagnifyingGlassIcon className='w-6 h-6 text-gray-600 lg:text-white' />,
            action: handleView
        }
    ];

    const seeFullAccountList = () => {
        setCurrenPage(1);
        setSearchInput(null);
        setSortString('');
        setFilterString('')
        setIntervalsFilter({
            ...intervalsFilter,
            start: null,
            end: null,
        })
        setFiltersApplied(false);
    }

    return (
        <>
            {isPending && <SecondaryLoader />}
            <div className="w-full">
                <Nav>
                    <div className="w-full">
                        <span className="text-3xl text-white font-bold">
                            Contribuyentes
                        </span>
                    </div>
                </Nav>
            </div>
            <div className='min-h-full md:min-h-[calc(100vh-4rem)]'>
                {error &&
                    <div className='w-full sticky top-[68px] md:top-1 mt-20 md:mt-4 z-[35]'>
                        <LongAlert title={error} show={error != null} onClose={() => setError(null)} />
                    </div>
                }
                <div className="w-full px-4 pt-4">
                    <div className="hidden md:flex justify-between items-center w-full">
                        <h1 className="hidden md:block text-4xl font-bold text-left text-gray-900">
                            Contribuyentes
                        </h1>
                    </div>
                </div>
                <div className={`w-full px-4 py-0 md:py-4 ${error != null ? 'mt-0' : 'mt-16 md:mt-0'} `}>
                    {isLoading ?
                        <>
                            <div className='w-full h-12 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse my-4' />
                            </div>
                            <Table title='Contribuyentes' data={accounts} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                        </>
                        :
                        <>
                            {accounts.length > 0 ?
                                <>
                                    <SearchBar value={searchInput} setValue={setSearchInput} placeholder_desktop="Nombre, correo o RFC" />
                                    <section aria-labelledby="filter-heading" className='pt-4'>
                                        <div className="flex items-center justify-between">
                                            <Sort options={sortOptions} title='Ordenar' setOptions={setSortOptions} />
                                            <div className='flex items-center gap-6'>
                                                <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                                    Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]' /></span>
                                                </button>
                                                <div className='hidden md:flex'>
                                                    <DateIntervalV2 filters={intervalsFilter} setFilters={setIntervalsFilter} setFiltersApplied={setFiltersApplied} />
                                                </div>
                                                <CSVLink data={dataCsv} headers={headers} filename={`contribuyentes.csv`}>
                                                    {!loadingCSV ?
                                                        <PrimaryButton isFullWidth>
                                                            Descargar CSV <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                                        </PrimaryButton>
                                                        :
                                                        <PrimaryButton disabled isFullWidth>
                                                            Cargando... <DocumentArrowDownIcon className='w-5 h-5 ml-2 inline-block' />
                                                        </PrimaryButton>
                                                    }

                                                </CSVLink>
                                            </div>
                                        </div>
                                    </section>
                                    <div className='mt-4'>
                                        <Table title='Contribuyentes' data={accounts} isLoadingData={isLoading} columns={tableColumns} actions={columnActions} />
                                    </div>
                                    <div className='mt-4'>
                                        <Pagination pages={pagination?.pages?.length} currentPage={currentPage} setCurrentPage={setCurrenPage} />
                                    </div>
                                </>
                                :
                                <>
                                    <div className='mb-4'>
                                        <SearchBar value={searchInput} setValue={setSearchInput} />
                                    </div>
                                    <EmptyState title='No se encontraron contribuyentes' text='No se encontraron resultados para esta búsqueda, verifica la información o intenta con otra.'>
                                        <div className='mt-6 hidden md:block'>
                                            <AccentButton onClick={seeFullAccountList}>
                                                Ver la lista completa
                                            </AccentButton>
                                        </div>
                                        <div className='mt-6 w-full md:hidden'>
                                            <AccentButton isFullWidth onClick={seeFullAccountList}>
                                                Ver la lista completa
                                            </AccentButton>
                                        </div>
                                    </EmptyState>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <SlideOver open={openSlide} setOpen={setOpenSlide} title='Filtros'>
                <DateIntervalV2 filters={intervalsFilter} openSlide={openSlide} setOpenSlide={setOpenSlide} setFilters={setIntervalsFilter} setFiltersApplied={setFiltersApplied} />
            </SlideOver>
            <ModalNotification
                type={statusModal}
                showModal={showModal}
                setShowModal={setShowModal}
                title={modalInfo[statusModal]?.title || ""}
                description={modalInfo[statusModal]?.description || ""}
                onConfirm={updateAccountStatusFn}
            />
        </>
    )
}

export default Taxpayers;