import React, { useState, useEffect, useContext } from 'react';
import { getPaymentLinks } from '../../apiClient/operations/paymentLinksOperations';
import { useHistory } from 'react-router-dom';
import Pagination from '../../tailwindUI/Pagination';
import Nav from '../../hooks/Nav';
import useConvertUnixToDate from '../../hooks/useConvertUnixToDate';
import useFormatDate from '../../hooks/useFormatDate';
import { AlertContext } from '../../context/AlertContext';
import { AccountContext } from '../../context/AccountContext';
import useFormatterCurrency from '../../hooks/useFormatterCurrency';
import useGetStatusExpired from '../../hooks/useGetStatusExpired';
import Table from '../../tailwindUI/Table';
import EmptyState from '../../tailwindUI/EmptyState';
import { EyeIcon, ClipboardDocumentIcon, PlusIcon, AdjustmentsHorizontalIcon, TrashIcon } from '@heroicons/react/24/outline';
import usePagination from '../../tailwindUI/usePagination';
import PrimaryButton from '../../tailwindUI/PrimaryButton';
import SearchBar from '../../tailwindUI/SearchBar';
import useFilter from '../../tailwindUI/useFilter';
import useSort from '../../tailwindUI/useSort';
import Sort from '../../tailwindUI/Sort';
import Filters from '../../tailwindUI/Filters';
import SlideOver from '../../tailwindUI/SlideOver';
import useScrollPosition from '../../hooks/useScrollPosition';
import Notification from '../../tailwindUI/Notification';

const tableColumns = [
    { heading: 'Fecha', value: 'created_formated' },
    { heading: 'Cliente', value: 'name', main: true, subvalue: 'email'},
    { heading: 'Monto', value: 'total_amount_formated'},
    { heading: 'Tipo de link', value: 'type'},
    { heading: 'Tipo de pago', value: 'type_translated'},
    { heading: 'Estatus', value: 'status_formated', badge: true},
];

const translatedLinkType = {
    "payment_link": "Normal",
    "universal_payment_link": "Universal",
    "payment_contract_renovation": "Renovación",
}

function PaymentLink() {
    const linkStatus = {
        "pending": "Activo",
        "paid": "Pagado",
        "expired": "Expirado",
        "canceled": "Cancelado"
    }

    let history = useHistory();
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const { formatterCurrency } = useFormatterCurrency();
    const { formatDate } = useFormatDate();
    const { convertUnixToDate } = useConvertUnixToDate();
    const { getStatusExpired } = useGetStatusExpired();
    const paginate = usePagination();
    const sortItem = useSort();
    const filterItem = useFilter();
    const setScrollPosition = useScrollPosition();
    const { setAccount } = useContext(AccountContext);
    const [paymentLinks, setpaymentLinks] = useState([]);
    const { setAlert } = useContext(AlertContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [term, setTerm] = useState(null);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [filterString, setFilterString] = useState('');
    const [sortString, setSortString] = useState('');
    const [openSlide, setOpenSlide] = useState(false);
    const [notification, setNotification] = useState(false);
    const [sortOptions, setSortOptions] = useState([
        { id: 1, name: 'Los más nuevos', field: 'created', sort: 'reverse', active: false },
        { id: 2, name: 'Los más antiguos', field: 'created', sort: 'direct', active: false }
    ]);
    const [filters, setFilters] = useState([
        {
            id: 1,
            name: 'Tipo de link',
            value: 'type',
            open: false,
            options: [
                { id: 1, value: 'payment_link', label: 'Normal', applied: false, checked: false, filter_id: 1 },
                { id: 2, value: 'universal_payment_link', label: 'Universal', applied: false, checked: false, filter_id: 1 },
                { id: 3, value: 'payment_contract_renovation', label: 'Renovación', applied: false, checked: false, filter_id: 1 },
            ],
        },
        {
            id: 2,
            name: 'Tipo de pago',
            value: 'recurrent',
            open: false,
            options: [
                { id: 1, value: 'false', label: 'Pago único', applied: false, checked: false, filter_id: 2 },
                { id: 2, value: 'true', label: 'Suscripción', applied: false, checked: false, filter_id: 2 },
            ],
        }, 
        {
            id: 3,
            name: 'Estatus',
            value: 'status',
            open: false,
            options: [
                { id: 1, value: 'pending', label: 'Activo', applied: false, checked: false, filter_id: 3 },
                { id: 2, value: 'paid', label: 'Pagado', applied: false, checked: false, filter_id: 3 },
                { id: 3, value: 'expired', label: "Expirado", applied: false, checked: false, filter_id: 3 },
                { id: 4, value: 'canceled', label: "Cancelado", applied: false, checked: false, filter_id: 3 },
            ],
        }
    ]);

    const getData = async (page) => {
        try {
            setIsLoading(true);
            setScrollPosition(0);
            const res = await getPaymentLinks(page, 10, term, sortString, filterString);
            setCurrentPage(page);
            setPagination(paginate(res.total_pages, 10, Math.ceil(res.total_items/10)));
            const data = res.data.map(item => {
                return {
                    ...item,
                    name: item?.account?.name || "Sin cliente",
                    email:item?.account?.email,
                    created_formated: formatDate(item.created, 'DD MMMM YYYY'),
                    total_amount_formated: formatterCurrency(item.price.total_amount/100),
                    type: translatedLinkType[item?.type] || item?.type,
                    type_translated: item.recurrent ? 'Suscripción' : 'Pago único',
                    status_formated: item.status !== 'paid' && item.status !== 'canceled' && getStatusExpired(convertUnixToDate(item.expires_at)) == 'expired' ? linkStatus['expired'] : linkStatus[item.status]
                }
            })
            setpaymentLinks(data);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: e.message })
        }
    }

    const handleCleanFilters = () => {
        const newFilters = filters.map(filter => {
            const newOptions = filter.options.map(option => {
                return {
                    ...option,
                    applied: false,
                    checked: false
                }
            });
            return {
                ...filter,
                options: newOptions
            }
        });
        setFilters(newFilters);
        setFiltersApplied(false);
        setFilterString('');
        setNotification(true);
        setTimeout(() => {
            setNotification(false);
        }, 5000);
    }

    const handleSort = item => setSortString(sortItem(item));

    const handlePasteSearchInput = event => setTerm(event.clipboardData.getData('text'));

    const handleApplyFilters = () => {
        setFilterString(filterItem(filters));
        setFiltersApplied(false);
    }

    const handleViewLink = (item) => {
        history.push(`/payment-links/view/${item.id}`)
    }

    const copyLinkToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
    }

    const handleCopyLink = (item) => {
        copyLinkToClipboard(item.short_url).then(() => {
            setAlert({ active: true, type: 'success', message: 'Link copiado con éxito' })
        })
    }

    const columnActions = [
        {
            id: 1,
            name: 'Visualizar',
            type: 'primary',
            icon: <EyeIcon className='w-5 h-5 text-gray-600 lg:text-white'/>,
            action: handleViewLink,
        },
        {
            id: 2,
            name: 'Copiar link',
            type: 'secondary',
            icon: <ClipboardDocumentIcon className='w-5 h-5 text-gray-600 lg:text-gray-600'/>,
            action: handleCopyLink,
        },
    ];

    useEffect(() => {
        setAccount(null);
    }, []);

    useEffect(() => {
        getData(currentPage);
        return () => {
            setpaymentLinks([]);
        }
    }, []);

    useEffect(() => {
        getData(1);
        return () => {
            setpaymentLinks([]);
        }
    }, [sortString, filterString]);

    useEffect(() => {
        getData(currentPage);
        return () => {
            setpaymentLinks([]);
        }
    }, [currentPage]);

    useEffect(() => {
        term !== null && getData(1);
        return () => {
            setpaymentLinks([]);
        }
    }, [term]);

    useEffect(() => {
        const activeOptions = sortOptions.filter(option => option.active);
        activeOptions.length > 0 && handleSort(activeOptions[0]);
    }, [sortOptions]);

    useEffect(() => {
        filtersApplied && handleApplyFilters();
    }, [filtersApplied]);

    return (
        <>
            <div className="w-full h-full">
                <div className="w-full xs:h-full md:h-full">
                    <div className="w-full bg-white xs:h-auto md:h-full">
                        <Nav user={user}>
                            <div className="w-full">
                                <span className="text-3xl text-white font-bold">
                                    Link de cobro
                                </span>
                            </div>
                        </Nav>
                    </div>
                    <div className="w-full px-4 py-3">
                        <div className="flex justify-end lg:justify-between items-center w-full">
                            <h1 className="hidden lg:block text-4xl font-bold xs:text-center md:text-left">
                                Link de cobro
                            </h1>
                            <div className="flex gap-4">
                                <div className="mt-16 lg:mt-2 w-full lg:w-auto max-w-full lg:max-w-[250px]">
                                    <PrimaryButton isFullWidth={true} onClick={() => history.push('/payment-links/create-universal-link')}>
                                        <span className='text-[15px] md:text-sm py-0.5 md:py-1 font-medium'>Crear link universal</span>
                                        <PlusIcon className='w-5 h-5 font-bold ml-2' />
                                    </PrimaryButton>
                                </div>
                                <div className="mt-16 lg:mt-2 w-full lg:w-auto max-w-full lg:max-w-[150px]">
                                <PrimaryButton isFullWidth={true} onClick={() => history.push('/payment-links/create')}>
                                    <span className='text-[15px] md:text-sm py-0.5 md:py-1 font-medium'>Crear link</span>
                                    <PlusIcon className='w-5 h-5 font-bold ml-2' />
                                </PrimaryButton>
                            </div>
                            </div>
                        </div>
                        <Notification show={notification} message='Filtros limpiados correctamente' />
                    </div>
                    { isLoading ?
                        <div className='px-4 mt-4'>
                            <div className='w-full h-10 rounded-full bg-gray-300 animate-pulse'></div>
                            <div className='w-full flex justify-between'>
                                <div className='w-24 h-6 rounded-full bg-gray-300 animate-pulse mt-4'/>
                                <div className='w-44 h-6 rounded-full bg-gray-300 animate-pulse mt-4'/>
                            </div>
                        </div>
                        :
                        <div className='px-4 space-y-4 mt-4'>
                            <SearchBar value={ term } setValue={setTerm} placeholder_mobile='Ingresa un contribuyente' placeholder_desktop='¿Qué contribuyente deseas buscar?' onPaste={ handlePasteSearchInput }/>
                            <div className='w-full flex justify-between gap-4'>
                                <Sort options={sortOptions}  title='Ordenar' setOptions={setSortOptions}/>
                                <div className='flex items-center gap-6'>
                                    {filterString !== '' && <span className='cursor-pointer text-sm font-medium text-gray-700 hover:text-gray-900 flex gap-1 pt-0 md:pt-[1px]' onClick={handleCleanFilters}>Limpiar filtros <TrashIcon className='h-5 h-5' /></span>}
                                    <button type="button" className="flex items-center gap-1 text-sm font-medium text-gray-700 md:hidden" onClick={() => setOpenSlide(true)}>
                                        Filtros <span><AdjustmentsHorizontalIcon className='w-[18px] h-[18px]'/></span>
                                    </button>
                                    <Filters filters={ filters } setFilters={ setFilters } setFiltersApplied={setFiltersApplied} />
                                </div>
                            </div>
                        </div>
                    }
                    <div className='w-full p-4 mb-12 lg:mb-4'>
                        { isLoading ?
                            <Table title='Links de cobro' data={ paymentLinks } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                            :
                            <>
                                { paymentLinks.length > 0 ?
                                    <div className='space-y-4'>
                                        <Table title='Links de cobro' data={ paymentLinks } isLoadingData={ isLoading } columns={ tableColumns } actions={ columnActions } />
                                        <Pagination pages={ pagination?.totalPages } currentPage={ currentPage } setCurrentPage={ setCurrentPage } />
                                    </div>
                                    :
                                    <div className='w-full'>
                                        <EmptyState title='No se encontraron links de cobro' text='Verifica la información antes de continuar.' />
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
            <SlideOver open={ openSlide } setOpen={ setOpenSlide } title='Filtros'>
                <Filters filters={ filters } openSlide={ openSlide } setFilters={ setFilters } setOpenSlide={ setOpenSlide } setFiltersApplied={setFiltersApplied}/>
            </SlideOver>
        </>
    )
}

export default PaymentLink;