import React, { useContext, useEffect } from "react";
import { AlertContext } from "../../context/AlertContext";
import LinkInput from "../../tailwindUI/LinkInput";
import cookie from "js-cookie";

export function Admin() {
  const { setAlert } = useContext(AlertContext);
  const accessToken = cookie.get("access_token") || "";

  useEffect(() => {
    if (!accessToken) {
      setAlert({
        active: true,
        type: "error",
        message: "Error al obtener el access token",
      });
    }
  }, [accessToken]);

  return (
    <>
      <div className="w-full h-full">
        <div className="w-full xs:h-full md:h-full">
          <div className="mt-10 md:mt-0 w-full px-5 py-5  xs:h-auto md:h-1/12">
            <div className=" justify-between items-center w-full">
              <h1 className="hidden md:flex text-4xl font-bold text-left">
                Admin
              </h1>
            </div>
          </div>

          <div className="w-full h-full border border-v2-gray-border-tables rounded-10 bg-white xs:mt-0 md:mt-3 ">
            <div className="w-full xs:px-2 lg:px-4 py-4 border-b border-v2-gray-border-tables flex items-center ">
              <div className="xs:w-11/12 md:w-10/12 text-xl font-semibold text-v2-input-text">
                Sesión de administrador
              </div>
            </div>
            <div className="my-6 xs:px-3 md:px-4">
              <div className="space-y-2 pb-7">
                <LinkInput
                  label="Access token"
                  placeholder="Access token"
                  link={accessToken}
                  disabled={!accessToken}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
