import apiClient from '../apiClient';
import { apiBaseUrl } from '../../config';

export const createShortUrl = async (body) => {
    return apiClient.post(`${apiBaseUrl}/url-shortener/admin`, body).then(response => {
        return response;
    });
}

export const getShortLinks = async (search, page, limit, sort) => {
    const url = `${apiBaseUrl}/url-shortener/admin/search?limit=${limit}&page=${page}&sort=${sort}&q=wildCard~${search}`;
    return apiClient.get(url).then((response) => {
      return response;
    });
}

export const deleteShortLink = async (urlId) => {
    return apiClient.delete(`${apiBaseUrl}/url-shortener/admin/${urlId}`).then(response => {
        return response;
    })
}