import React, { useState, useRef, useContext } from 'react';
import { ArrowRightOnRectangleIcon, DocumentTextIcon, UserIcon, UsersIcon, UserGroupIcon, CreditCardIcon, WrenchScrewdriverIcon, DocumentPlusIcon, DocumentMagnifyingGlassIcon, CalendarDaysIcon, BanknotesIcon, DocumentChartBarIcon, LinkIcon } from '@heroicons/react/24/outline';
import { Transition } from "@headlessui/react";
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Timer from './Timer';
import { logout } from '../apiClient/operations/sessionOperations';
import PermissionsGate from '../cognito/permissions/PermissionsGate';
import { AuthContext } from '../cognito/AuthProvider';
import { allowedRolesForRoutes } from '../routes/SideBarRoutes';

function Nav({ children, user }) {

    const { user: userFromContext } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const myRef = useRef();
    let history = useHistory();
    const navItems = [
        {
            section: "Cotizaciones",
            routes: [
                {
                    name: "Nueva cotización",
                    path: "/",
                    subpath: [
                        '/new-quote',
                    ],
                    icon: DocumentPlusIcon,
                    allowedRoles: allowedRolesForRoutes.quoterRoles
                },
                {
                    name: "Lista de cotizaciones",
                    path: "/generated-quotes",
                    subpath: [
                        '/view-quote',
                    ],
                    icon: DocumentMagnifyingGlassIcon,
                    allowedRoles: allowedRolesForRoutes.quotesRoles
                }
            ]
        },
        {
            section: "Contratos",
            routes: [
                {
                    name: "Contratos",
                    path: "/contracts",
                    subpath: [
                        '/contracts/view'
                    ],
                    icon: DocumentTextIcon,
                    allowedRoles: allowedRolesForRoutes.contractsRoles
                }
            ]
        },
        {
            section: "Cartera",
            routes: [
                {
                    name: "Suscripciones",
                    path: "/subscriptions",
                    subpath: [
                        '/subscriptions/create',
                        '/subscriptions/view'
                    ],
                    icon: CalendarDaysIcon,
                    allowedRoles: allowedRolesForRoutes.subscriptions
                },
                {
                    name: "Reporte de cobros",
                    path: "/charges_report",
                    subpath: [
                        '/charges/view'
                    ],
                    icon: DocumentChartBarIcon,
                    allowedRoles: allowedRolesForRoutes.charges
                },
                {
                    name: "Cobranza",
                    path: "/portfolio/collecting",
                    subpath: [
                        '/portfolio/collecting'
                    ],
                    icon: BanknotesIcon,
                    allowedRoles: allowedRolesForRoutes.collecting
                }
            ]
        },
        {
            section: "Clientes",
            routes: [
                {
                    name: "Leads",
                    path: "/leads",
                    subpath: [
                        '/leads/create',
                        '/leads/view'
                    ],
                    icon: UserGroupIcon,
                    allowedRoles: allowedRolesForRoutes.leadsRoles
                },
                {
                    name: "Prospectos",
                    path: "/prospects",
                    subpath: [
                        '/prospects/view'
                    ],
                    icon: UsersIcon,
                    allowedRoles: allowedRolesForRoutes.prospectsRoles
                },
                {
                    name: "Contribuyentes",
                    path: "/taxpayers",
                    subpath: [
                        '/taxpayers/view'
                    ],
                    icon: UserIcon,
                    allowedRoles: allowedRolesForRoutes.taxpayersRoles
                }
            ]
        },
        {
            section: "Links",
            routes: [
                 {
                    name: "Links cortos",
                    path: "/short-links",
                    subpath: [],
                    icon: LinkIcon,
                    allowedRoles: allowedRolesForRoutes.paymentLinksRoles
                },
                {
                    name: "Links de cobro",
                    path: "/payment-links",
                    subpath: [],
                    icon: CreditCardIcon,
                    allowedRoles: allowedRolesForRoutes.paymentLinksRoles
                }
            ],
        },
        {
            section: "Incidencias",
            routes: [
                {
                    name: "Añadir incidencia",
                    path: "/incidents",
                    subpath: [],
                    icon: WrenchScrewdriverIcon,
                    allowedRoles: allowedRolesForRoutes.incidentsRoles
                }
            ]
        },
        {
            section: "Accesos",
            routes: [
                {
                    name: "Añadir acceso al portal",
                    path: "/access",
                    subpath: [],
                    icon: UserIcon,
                    allowedRoles: allowedRolesForRoutes.incidentsRoles
                }
            ]
        }
    ]

    return (
        <>
            {history.location.pathname != '/' &&
                <div className={`xs:hidden w-full bg-white h-16 lg:flex justify-end items-center px-8 z-1`}>
                    <div className="w-full">
                        <Timer />
                    </div>
                </div>
            }
            <div className="block lg:hidden w-full fixed top-0 z-[30]">
                <nav className='bg-v2-blue-text-login w-full'>
                    <div className="mx-auto px-5">
                        <div className="flex max-w-screen-lg mx-auto justify-between h-16">
                            <div className="flex w-auto w-full justify-start items-center">
                                {children}
                            </div>
                            <div className="flex xs:block lg:hidden ml-6 -mr-3">
                                <button
                                    onClick={() => setIsOpen(!isOpen)}
                                    type="button"
                                    className="bg-transparent inline-flex items-center justify-center p-2 rounded-md text-white-700 text-white hover:bg-transparent"
                                    aria-controls="mobile-menu"
                                    aria-expanded="false"
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {!isOpen ? (
                                        <svg
                                            className="block h-12 w-12 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="block h-12 w-12"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <Transition
                        show={isOpen}
                        enter="transition ease-out duration-50 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {() => (<>
                            <div className='xs:block lg:hidden border-t border-white h-screen' id="mobile-menu">
                                <div className="w-full">
                                    <div ref={myRef} className="w-full h-auto max-h-[600px] overflow-scroll">
                                        <div className="w-full space-y-0.5">
                                            {navItems.map((item, i) =>
                                                <div key={i} >
                                                    <PermissionsGate allowedRoles={item.routes?.map(route => route.allowedRoles)?.flat()}>
                                                        <div className="pt-2 text-white text-base font-bold tracking-wide px-6">
                                                            {item.section}
                                                        </div>
                                                    </PermissionsGate>
                                                    {item.routes?.map((route, i) =>
                                                        <PermissionsGate key={i} allowedRoles={route.allowedRoles}>
                                                            <div className="mt-2">
                                                                <Link to={route.path}>
                                                                    <div className={`${((history.location.pathname.includes(route.path) && route.path !== '/') || (route.subpath.includes(history.location.pathname) && history.location.pathname !== '/') || history.location.pathname == route.path) ? 'bg-primary text-white' : 'text-white/60'} w-full pl-10 flex items-center cursor-pointer py-2`} onClick={() => setIsOpen(!isOpen)}>
                                                                        <span className="flex">
                                                                            <route.icon className='w-6' />
                                                                        </span>
                                                                        <span className='ml-5'>
                                                                            {route.name}
                                                                        </span>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </PermissionsGate>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-shrink-0 border-t border-gray-200 py-4 px-8 mt-10">
                                        <div className="group block w-full flex-shrink-0">
                                            <div className="w-full flex items-center">
                                                <div>
                                                    <div className='flex items-center justify-center h-10 w-10 ml-1 rounded-full border border-white/30'>
                                                        <img
                                                            className="inline-block h-full w-full rounded-full"
                                                            src={user?.picture || userFromContext?.picture || 'https://cdn.fixat.mx/intranet/icono-principal-fixat.png'}
                                                            alt="Usuario operativo de Fixat"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='ml-3 -mt-1'>
                                                    <p className="text-sm font-medium text-white group-hover:text-white">{user?.givenName || userFromContext?.givenName}</p>
                                                    <p className="text-sm font-medium text-white group-hover:text-white pt-1 -ml-[3px] cursor-pointer flex items-center gap-1 underline" onClick={() => logout()}><ArrowRightOnRectangleIcon className='w-5 h-5' /> Cerrar sesión</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                    </Transition>
                </nav>
            </div>
        </>
    )
}

Nav.propTypes = {
    children: PropTypes.any,
    user: PropTypes.object
}

export default Nav