import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import PropTypes from "prop-types";

import {
  createShortUrl,
  deleteShortLink,
  getShortLinks,
} from "../../apiClient/operations/shortLinksOperations";

import Nav from "../../hooks/Nav";
import useFormatDate from "../../hooks/useFormatDate";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import SecondaryLoader from "../../hooks/SecondaryLoader";
import { AlertContext } from "../../context/AlertContext";
import { ModalNotification } from "../modals/ModalNotification";
import { DeleteModal } from "./DeleteModal";

import ButtonOrange from "../../tailwindUI/ButtonOrange";
import EmptyState from "../../tailwindUI/EmptyState";
import Pagination from "../../tailwindUI/Pagination";
import SearchBar from "../../tailwindUI/SearchBar";
import SecondaryButton from "../../tailwindUI/SecondaryButton";
import Table from "../../tailwindUI/Table";
import usePagination from "../../tailwindUI/usePagination";
import LinkInput from "../../tailwindUI/LinkInput";
import Sort from "../../tailwindUI/Sort";

import {
  InformationCircleIcon,
  LinkIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";

const tableColumns = [
  { heading: "Id", value: "code", main: true },
  { heading: "Link corto", value: "short_url" },
  {
    heading: "Link original",
    value: "custom_original_url",
    description: "original_url",
    descriptionClass: "flex items-center",
    align: "left",
  },
  {
    heading: "Fecha de creación",
    headingClass: "text-nowrap",
    value: "created_at",
  },
  { heading: "Aperturas", value: "clicks" },
];

const formDefaultValues = { originalUrl: "", code: "" };
const LIMIT = 10;

export function ShortLinks() {
  const user = JSON.parse(localStorage.getItem("user")) || {};
  const { setAlert } = useContext(AlertContext);
  const { formatDate } = useFormatDate();
  const [textCopied, copyText] = useCopyToClipboard();

  const [shortLinks, setShortLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const [formData, setFormData] = useState(formDefaultValues);
  const [formErrors, setFormErrors] = useState({
    originalUrl: null,
    code: null,
  });

  const [newShortLink, setNewShortLink] = useState(null);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("desc");
  const [sortOptions, setSortOptions] = useState([
    {
      id: 1,
      name: "Los más nuevos",
      field: "created_at",
      sort: "desc",
      active: true,
    },
    {
      id: 2,
      name: "Los más antiguos",
      field: "created_at",
      sort: "asc",
      active: false,
    },
  ]);

  const paginate = usePagination();
  const [pagination, setPagination] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const CustomTooltip = ({ id, content, place }) => (
    <>
      <span data-tip data-for={id}>
        <InformationCircleIcon className="cursor-pointer w-5 h-5 text-gray-300" />
      </span>
      <ReactTooltip id={id} place={place} effect="solid">
        {content}
      </ReactTooltip>
    </>
  );

  const CopyButton = ({ link }) => {
    return (
      <div className="flex items-center gap-3">
        <SecondaryButton
          onClick={() => {
            setAlert({
              active: true,
              type: "success",
              message: "Link copiado con éxito",
            });
            copyText(link.short_url);
          }}
        >
          <ClipboardDocumentIcon className="h-5 w-5" />
        </SecondaryButton>
        <p>{link.short_url}</p>
      </div>
    );
  };

  const handleDeleteShortLink = async () => {
    try {
      setIsPending(true);
      const res = await deleteShortLink(itemToDelete.id);
      const newLinks = shortLinks.filter((link) => link.id !== itemToDelete.id);
      setShortLinks(newLinks);
      setAlert({
        active: true,
        type: "success",
        message: res.message || "Link eliminado",
      });
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Error al procesar la solicitud",
      });
    } finally {
      setIsPending(false);
      setShowDeleteModal(false);
      setItemToDelete(null);
    }
  };

  const columnActions = [
    {
      id: 1,
      name: "Borrar",
      type: "ghost",
      icon: <TrashIcon className="w-5 h-5 text-gray-600" />,
      action: (item) => {
        setShowDeleteModal(true);
        setItemToDelete(item);
      },
    },
  ];

  const getShortLinksData = async () => {
    try {
      setIsLoading(true);
      const res = await getShortLinks(search, currentPage, LIMIT, sort);
      setShortLinks(
        res.data.urls.map((link) => ({
          ...link,
          created_at: formatDate(link.created_at, "DD MMMM YYYY"),
          short_url: <CopyButton link={link} />,
          custom_original_url: (
            <p className="text-wrap break-all">
              {link.original_url.slice(0, 40)}...
            </p>
          ),
        }))
      );
      setPagination(
        paginate(res.total_pages, LIMIT, Math.ceil(res.total_items / LIMIT))
      );
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: "Error al procesar tu solicitud",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnChange = (e) => {
    const id = e.target.id;
    let value = e.target.value;
    const regexCode = /^[a-zA-Z0-9-]+$/;
    const regexUrl = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(:\d+)?(\/[^\s]*)?$/;

    switch (id) {
      case "originalUrl":
        if (value.length === 0) {
          setFormErrors({
            ...formErrors,
            originalUrl: value ? "" : "Ingresa una URL para continuar.",
          });
        } else if (value.length > 0 && !regexUrl.test(value)) {
          setFormErrors({
            ...formErrors,
            originalUrl: "Ingresa una URL válida para continuar.",
          });
        } else {
          setFormErrors({ ...formErrors, originalUrl: "" });
        }
        break;
      case "code":
        if (value.length > 30) {
          setFormErrors({
            ...formErrors,
            code: "Ingresa solo una palabra de no más de 30 caracteres.",
          });
          value = value.slice(0, 30);
        } else if (value && !regexCode.test(value)) {
          setFormErrors({
            ...formErrors,
            code: "Solo se permiten letras, números y guion medio (-).",
          });
        } else {
          setFormErrors({ ...formErrors, code: "" });
        }
        break;
      default:
        break;
    }
    setFormData((currentData) => ({ ...currentData, [id]: value }));
  };

  const handlePasteSearchInput = (event) =>
    setSearch(event.clipboardData.getData("text"));

  const handleOnSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsPending(true);
      const newShortLink = await createShortUrl({
        url: formData.originalUrl,
        ...(formData.code && { code: formData.code }),
      });

      setNewShortLink(newShortLink);
      setShortLinks((links) => [
        {
          ...newShortLink,
          created_at: formatDate(newShortLink.created_at, "DD MMMM YYYY"),
          short_url: <CopyButton link={newShortLink} />,
          custom_original_url: (
            <p className="text-wrap break-all">
              {newShortLink.original_url.slice(0, 40)}...
            </p>
          ),
        },
        ...links,
      ]);
      setShowModal(true);
    } catch (error) {
      setAlert({
        active: true,
        type: "error",
        message: error.message || "Error al procesar la solicitud",
      });
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    getShortLinksData();
  }, [currentPage, sort]);

  useEffect(() => {
    setCurrentPage(1);
    getShortLinksData();
  }, [search]);

  useEffect(() => {
    const activeOptions = sortOptions.filter((option) => option.active);
    if (activeOptions.length > 0) {
      setSort(activeOptions[0].sort);
    }
  }, [sortOptions]);

  console.log(textCopied);

  return (
    <div className="w-full h-full">
      {isPending && <SecondaryLoader />}
      <div className="w-full xs:h-full md:h-full space-y-4">
        <div className="w-full bg-white xs:h-auto md:h-full">
          <Nav user={user}>
            <div className="w-full">
              <span className="text-3xl text-white font-bold">
                Links cortos
              </span>
            </div>
          </Nav>
        </div>
        <div className="w-full px-4 py-3 mt-14 lg:mt-0">
          <div className="flex justify-end lg:justify-between items-center w-full">
            <h1 className="hidden lg:block text-4xl font-bold xs:text-center md:text-left">
              Crear link corto
            </h1>
          </div>
        </div>
        <div className="w-full px-4 pt-6 lg:pt-0">
          <form onSubmit={handleOnSubmit} className="space-y-4">
            <div className="flex flex-col gap-2">
              <label htmlFor="originalUrl" className="text-gray-700 text-sm">
                Link original
              </label>
              <div className="relative w-full">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <LinkIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id="originalUrl"
                  autoComplete="off"
                  value={formData.originalUrl}
                  onChange={handleOnChange}
                  placeholder="http://"
                  className={`w-full rounded-md text-sm border border-gray-300 focus:ring-1 focus:ring-v2-blue-text-login focus:order-v2-blue-text-login bg-white pl-10 placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none ${
                    formErrors.originalUrl
                      ? "border  border-v2-red-error-services-selected focus:ring-v2-red-error-services-selected focus:border-v2-red-error-services-selected"
                      : ""
                  }`}
                />
              </div>
              {formErrors.originalUrl && (
                <span className="w-full text-red-500 text-sm">
                  {formErrors.originalUrl}
                </span>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <label htmlFor="originalUrl" className="text-gray-700 text-sm">
                  ID interno del link corto (opcional)
                </label>
                <CustomTooltip
                  id="code"
                  content="Este ID te servirá para buscarlo y también será la terminación(..mx/Identificador) del link corto"
                  place="right"
                />
              </div>
              <div className="flex flex-col lg:flex-row gap-4">
                <input
                  type="text"
                  id="code"
                  autoComplete="off"
                  value={formData.code}
                  onChange={handleOnChange}
                  placeholder="Ej. Webinar2025"
                  className={`min-w-[52%] rounded-md text-sm border border-gray-300 focus:ring-1 focus:ring-v2-blue-text-login focus:border -v2-blue-text-login bg-white placeholder-gray-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none ${
                    formErrors.code
                      ? "border border-v2-red-error-services-selected focus:ring-v2-red-error-services-selected focus:border-v2-red-error-services-selected"
                      : ""
                  }`}
                />
                <ButtonOrange
                  type="submit"
                  disabled={
                    !formData.originalUrl ||
                    formErrors.code ||
                    formErrors.originalUrl
                  }
                >
                  <div className="px-12">Crear</div>
                </ButtonOrange>
              </div>
              {formErrors.code && (
                <span className="w-full text-red-500 text-sm">
                  {formErrors.code}
                </span>
              )}
            </div>
          </form>
        </div>

        <div className="w-full px-4 flex flex-col gap-4 pt-4">
          <h2 className="text-gray-700 text-base">Historial de Links cortos</h2>
          <div className="w-full max-w-full lg:max-w-[65%]">
            <SearchBar
              value={search}
              setValue={setSearch}
              onPaste={handlePasteSearchInput}
              placeholder_desktop="Buscar por ID"
            />
          </div>
        </div>

        <div className="w-full px-4 pt-4 pb-12 lg:pb-6">
          <div className="mb-4 space-x-2">
            <span className="text-gray-700 text-sm">Ordenar por</span>
            <Sort
              options={sortOptions}
              title={sortOptions.find((option) => option.active).name}
              setOptions={setSortOptions}
            />
          </div>
          {isLoading ? (
            <Table
              title="Links cortos"
              data={shortLinks}
              isLoadingData={isLoading}
              columns={tableColumns}
              actions={columnActions}
            />
          ) : (
            <>
              {shortLinks.length > 0 ? (
                <div className="space-y-4">
                  <Table
                    title="Links cortos"
                    data={shortLinks}
                    isLoadingData={isLoading}
                    columns={tableColumns}
                    actions={columnActions}
                  />
                  <Pagination
                    pages={pagination?.totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              ) : (
                <div className="w-full">
                  <EmptyState
                    title={
                      search
                        ? "No se encontraron resultados"
                        : "Aún no existe nada en el historial"
                    }
                    text={
                      search
                        ? "Verifica la información que ingresaste"
                        : "Los links cortos que generes se guardarán en automático aquí."
                    }
                  >
                    {search && (
                      <div className="mt-4">
                        <ButtonOrange onClick={() => setSearch("")}>
                          Ver lista completa
                        </ButtonOrange>
                      </div>
                    )}
                  </EmptyState>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ModalNotification
        type="success"
        title="Link corto creado con éxito"
        description="Ya puedes copiar y pegar el link."
        content={
          <div className="mt-2">
            <LinkInput
              link={newShortLink?.short_url}
              showNotification={false}
            />
          </div>
        }
        showModal={showModal}
        setShowModal={setShowModal}
        onClose={() => {
          setFormData(formDefaultValues);
          setShowModal(false);
        }}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onDelete={handleDeleteShortLink}
        isPending={isPending}
      />
    </div>
  );
}

ShortLinks.propTypes = {
  link: PropTypes.object,
  id: PropTypes.string,
  content: PropTypes.node,
  place: PropTypes.string,
};
