import React, { useState } from "react";

import Nav from "../../hooks/Nav";
import Tabs from "../../tailwindUI/Tabs";
import { ROLES } from "../../cognito/permissions/permission-maps";
import UpdateDocumentsDate from "../tools/UpdateDocumentsDate";
import Invoices from "./Invoices";

import {
  DocumentArrowDownIcon,
  CurrencyDollarIcon,
  KeyIcon,
} from "@heroicons/react/24/solid";
import { Admin } from "./Admin";

const modules = [
  {
    id: 1,
    name: "Documentos",
    icon: DocumentArrowDownIcon,
    current: true,
    href: "#fiscal-documents",
    allowedRoles: [ROLES.root, ROLES.help_desk],
  },
  {
    id: 2,
    name: "Facturas",
    icon: CurrencyDollarIcon,
    current: false,
    href: "#facturas",
    allowedRoles: [ROLES.root, ROLES.help_desk],
  },
  {
    id: 3,
    name: "Admin",
    icon: KeyIcon,
    current: false,
    href: "#admin",
    allowedRoles: [ROLES.root, ROLES.help_desk],
  },
];

function HelpDesk() {
  const [tabs, setTabs] = useState(modules);
  const currentTab = tabs.find((tab) => tab.current)?.id;

  return (
    <div className="w-full">
      <div className="w-full">
        <Nav>
          <div className="text-white font-medium text-xl md:text-3xl xl:text-4xl flex gap-2 items-center">
            Help desk
          </div>
        </Nav>
      </div>
      <div className=" md:block w-full px-6 mt-24 lg:mt-0">
        <Tabs items={tabs} setItems={setTabs} />
        {currentTab === 1 && <UpdateDocumentsDate />}
        {currentTab === 2 && <Invoices />}
        {currentTab === 3 && <Admin />}
      </div>
    </div>
  );
}

export default HelpDesk;
