import React from "react";
import PropTypes from "prop-types";

import Modal from "../../tailwindUI/Modal";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/solid";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export function ModalNotification({
  type,
  title,
  description,
  isLoading,
  showModal,
  setShowModal,
  onClose,
  onConfirm,
  content,
}) {
  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[400px]"
    >
      <div className="space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-5 h-5 -right-3 -top-6 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <div className="flex flex-col items-center space-y-2">
          {(type === "success") && (
            <div className="rounded-full w-fit bg-emerald-100 p-3">
              <CheckIcon className="text-emerald-600 h-6 w-6" />
            </div>
          )}
          {type === "error" && (
            <div className="rounded-full w-fit bg-red-100 p-3">
              <ExclamationTriangleIcon className="text-red-600 h-6 w-6" />
            </div>
          )}
          <h2 className="text-gray-900 text-lg">{title}</h2>
          <p className="text-gray-500 text-sm text-center">{description}</p>
          {type === "confirm" && (
            <div className="flex flex-col gap-5 mt-6 w-full">
              <PrimaryButton
                isFullWidth={true}
                disabled={isLoading}
                onClick={onConfirm}
              >
                Aceptar
              </PrimaryButton>
              <SecondaryButton
                isFullWidth={true}
                disabled={isLoading}
                onClick={() => {
                  setShowModal(false);
                  onClose && onClose();
                }}
              >
                Cancelar
              </SecondaryButton>
            </div>
          )}
        </div>
        {content}
        {!content && type !== "confirm" && (
          <div className="pt-4">
            <PrimaryButton
              bgColor={type === "error" ? "bg-red-600" : ""}
              isFullWidth={true}
              onClick={() => {
                setShowModal(false);
                onClose && onClose();
              }}
              disabled={isLoading}
            >
              Aceptar
            </PrimaryButton>
          </div>
        )}
      </div>
    </Modal>
  );
}

ModalNotification.propTypes = {
  type: PropTypes.oneOf(["success", "error", "confirm"]),
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node,
  isLoading: PropTypes.bool,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};
