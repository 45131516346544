import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon, XMarkIcon, EllipsisVerticalIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { AlertContext } from '../../../context/AlertContext';
import { createLink, invalidateConnectionOperation } from '../../../apiClient/operations/linksOperations';
import SkeletonLoader from '../../../tailwindUI/SkeletonLoader';
import LinkInput from '../../../tailwindUI/LinkInput';
import useFormatDate from '../../../hooks/useFormatDate';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import Badge from '../../../tailwindUI/Badge';
import Modal from '../../../tailwindUI/Modal';
import SlideOver from '../../../tailwindUI/SlideOver';
import DocumentList from '../../../tailwindUI/DocumentList';
import MenuButton from '../../../tailwindUI/MenuButton';
import PermissionsGate from '../../../cognito/permissions/PermissionsGate';
import { ROLES } from '../../../cognito/permissions/permission-maps';
import { s3Bucket } from '../../../config';
import PrimaryButton from '../../../tailwindUI/PrimaryButton';
import SecondaryLoader from '../../../hooks/SecondaryLoader';
import { getConnections, validateConnection } from '../../../apiClient/operations/connectionsOperations';
import { AuthContext } from '../../../cognito/AuthProvider';

const allowedRolesToPassword = [ROLES.root, ROLES.help_desk, ROLES.administrators, ROLES.operations, ROLES.customerService, ROLES.collections, ROLES.collections_manager, ROLES.operationsAtc];

function Efirma({ account }) {

    const { formatDate } = useFormatDate();
    const [efirmaPasswordCopied, copyEfirmaPassword] = useCopyToClipboard();
    const [ciecPasswordCopied, copyCiecPassword] = useCopyToClipboard();
    const { setAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isLoadingLink, setIsLoadingLink] = useState(false);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [invalidConnection, setInvalidConnection] = useState('');
    const [showInvalidateModal, setShowInvalidateModal] = useState(false);
    const [connectionLinks, setConnectionLinks] = useState([]);
    const [connectionLink, setConnectionLink] = useState([]);
    const [connections, setConnections] = useState(null);
    const [lastEfirma, setLastEfirma] = useState(null);
    const [lastCiec, setLastCiec] = useState(null);
    const [connectionDetails, setConnectionDetails] = useState(null);
    const [showEfirmaHistory, setShowEfirmaHistory] = useState(false);
    const [showCiecHistory, setShowCiecHistory] = useState(false);
    const { user } = React.useContext(AuthContext);
    const allowedRolesToInvalidate = [ROLES.root, ROLES.help_desk]

    const statusConnection = (type) => {
        let hidden = true
        const connectionsSort = connections?.filter(connection => connection?.credentials?.type == type)
        connectionsSort?.sort((a, b) => {
            return b.created - a.created
        })
        const canInvalidate = user?.roles.some(role => allowedRolesToInvalidate.includes(role))
        if (connectionsSort?.length > 0) {
            if (canInvalidate || connectionsSort[0].status === 'pending') {
                hidden = false
            }
        }
        return hidden
    }

    const openInvalidConnection = (type) => {
        setShowInvalidateModal(true)
        setInvalidConnection(type)
    }

    const efirmaActions = [
        {
            id: 1,
            name: 'Ver historial',
            action: () => setShowEfirmaHistory(true),
            hidden: connections?.filter(connection => connection?.credentials?.type == 'efirma')?.length <= 1
        },
        {
            id: 2,
            name: 'Crear link de subida',
            action: () => createConnectionsLink(account?.id, 'sat_credentials_efirma')
        },
        {
            id: 3,
            name: 'Invalidar acceso',
            action: () => openInvalidConnection('efirma'),
            hidden: statusConnection('efirma')
        }
    ];
    const ciecActions = [
        {
            id: 1,
            name: 'Ver historial',
            action: () => setShowCiecHistory(true),
            hidden: connections?.filter(connection => connection?.credentials?.type == 'ciec')?.length <= 1
        },
        {
            id: 2,
            name: 'Crear link de subida',
            action: () => createConnectionsLink(account?.id, 'sat_credentials_ciec')
        },
        // {
        //     id: 3,
        //     name: 'Reintentar validación',
        //     action: () => retryValidation(account?.id, lastCiec?.id),
        //     hidden: !(lastCiec?.status == 'pending') && !(lastCiec?.status == 'invalid_provider')
        // },
        {
            id: 4,
            name: 'Invalidar acceso',
            action: () => openInvalidConnection('ciec'),
            hidden: statusConnection('ciec')
        },
        {
            id: 5,
            name: 'Validar acceso',
            action: () => validateCiecConnection(account?.id, lastCiec?.id),
        }
    ];

    useEffect(async () => {
        if (!account) return;

        try {
            setIsLoading(true);
            const res = await getConnections(account.id);
            let connections = res.data;

            let hasEfirma = connections?.some(connection => connection?.credentials?.type == 'efirma');
            let hasCiec = connections?.some(connection => connection?.credentials?.type == 'ciec');

            if (connections && (!hasEfirma || !hasCiec)) {
                const oldConnection = connections?.findLast(connection => connection?.credentials?.type == undefined);

                if (oldConnection) {
                    connections.push({
                        ...oldConnection,
                        credentials: {
                            type: "ciec",
                            rfc: oldConnection?.credentials?.rfc,
                            password: oldConnection?.credentials?.password
                        }
                    });
                    hasCiec = true;
                    if (oldConnection?.credentials?.fiel && oldConnection?.credentials?.fiel != "") {
                        connections.push({
                            ...oldConnection,
                            credentials: {
                                type: "efirma",
                                rfc: oldConnection?.credentials?.rfc,
                                password: (oldConnection?.credentials?.fiel_password || oldConnection?.credentials?.password),
                                fiel: oldConnection?.credentials?.fiel
                            }
                        });
                        hasEfirma = true;
                    } else if (oldConnection?.credentials?.cer && oldConnection?.credentials?.key) {
                        connections.push({
                            ...oldConnection,
                            credentials: {
                                type: "efirma",
                                ...oldConnection?.credentials
                            }
                        });
                        hasEfirma = true;
                    }
                }
            }

            if (!connections || !hasEfirma || !hasCiec) {
                const request = {
                    account: account.id,
                    types: [
                        ...(!hasEfirma ? [{
                            type: "sat_credentials_efirma",
                            link_expires: 0
                        }] : []),
                        ...(!hasCiec ? [{
                            type: "sat_credentials_ciec",
                            link_expires: 0
                        }] : [])
                    ]
                }
                const links = await getConnectionLinks(request);
                setConnectionLinks(links);
            }
            setIsLoading(false);
            setConnections(connections.sort((a, b) => {
                return a.created - b.created
            }));

            const lastEfirma = connections?.findLast(connection => connection?.credentials?.type == 'efirma' && (connection?.status == 'active' || connection?.status == 'pending' || connection?.status == 'invalid' || connection?.status == 'expired' || connection?.status == 'invalid_provider' ));
            setLastEfirma(lastEfirma);
            const lastCiec = connections?.findLast(connection => connection?.credentials?.type == 'ciec' && (connection?.status == 'active' || connection?.status == 'pending' || connection?.status == 'invalid' || connection?.status == 'invalid_provider'));
            setLastCiec(lastCiec);
        } catch (error) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }, [account]);

    useEffect(() => {
            const lastEfirma = connections?.findLast(connection => connection?.credentials?.type == 'efirma' && (connection?.status == 'active' || connection?.status == 'pending' || connection?.status == 'invalid' || connection?.status == 'expired' || connection?.status == 'invalid_provider'));
            setLastEfirma(lastEfirma);
            const lastCiec = connections?.findLast(connection => connection?.credentials?.type == 'ciec' && (connection?.status == 'active' || connection?.status == 'pending' || connection?.status == 'invalid' || connection?.status == 'invalid_provider'));
            console.log(lastCiec)
            setLastCiec(lastCiec);
    }, [connections]);



    const getConnectionLinks = async (request) => {
        try {
            const links = await createLink(request);
            return links.data;
        } catch (e) {
            setIsLoading(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }

    const createConnectionsLink = async (accountId, type) => {
        if (isLoadingLink) return;
        setIsLoadingLink(true);
        try {
            const request = {
                account: accountId,
                types: [
                    {
                        type,
                        link_expires: 0
                    }
                ]
            }
            const link = await getConnectionLinks(request);
            setConnectionLink(link[0]);
            setIsLoadingLink(false);
            setShowLinkModal(true);
        } catch (e) {
            console.log("e:", e);
            setIsLoadingLink(false);
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        }
    }

    // const retryValidation = async (accountId, connectionId) => {
    //     if (!accountId || !connectionId) return;

    //     setLoader(true);
    //     try {
    //         const validatedCiec = await retryCiecValidation(accountId, connectionId);
    //         setLastCiec(validatedCiec);
    //         setConnections(connections => connections.map(connection => {
    //             return connection.id == validatedCiec.id ? validatedCiec : connection
    //         }));
    //         setAlert({ active: true, type: 'success', message: "La CIEC se validó correctamente" });
    //         setLoader(false);
    //     } catch (e) {
    //         setAlert({ active: true, type: 'error', message: e.message })
    //         setLoader(false);
    //     }
    // }

    const validateCiecConnection = async (accountId, connectionId) => {
        if(!accountId || !connectionId) return;
        setLoader(true)
        try {
            const response = await validateConnection(accountId, connectionId)
            setLastCiec({...response, status: response.status})
            setAlert({active: true, type: 'success', message: "La CIEC se validó correctamente"})
        } catch (e) {
            console.log({error: e})
            setAlert({ active: true, type: 'error', message: e.message})
        } finally {
            setLoader(false)
        }
    }

    const setInvaliteConnection = () => {
        setIsLoading(true)
        setShowInvalidateModal(false)
        const connection_id = connections?.findLast(connection => connection?.credentials?.type === invalidConnection && connection?.status !== 'invalid')?.id
        console.log(connection_id)
        invalidateConnectionOperation(account.id, connection_id).then((response) => {
            setIsLoading(false)
            const updateConnections = connections.map((connection) => {
                if (connection.id == response.id) {
                    return { ...response }
                }
                return connection
            })
            updateConnections?.credentials?.type == "efirma" ? setLastEfirma(updateConnections) : setLastCiec(updateConnections)
            setConnections(updateConnections)
            setAlert({ active: true, type: 'success', message: 'El acceso ha sido invalidado' })
        }).catch((error) => {
            setIsLoading(false)
            console.log(error)
            setAlert({ active: true, type: 'error', message: 'Error al procesar tu solicitud' })
        })
    }

    const downloadError = e => {
        setAlert({ active: true, type: 'error', message: e.message })
    }

    return (
        <>
            {loader && <SecondaryLoader />}
            <section className='space-y-4'>
                {isLoading ? (
                    <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                        <div className="px-4 py-5 sm:px-6 flex gap-4">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Accesos al SAT</h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <SkeletonLoader />
                                </div>
                                <div className="sm:col-span-1">
                                    <SkeletonLoader />
                                </div>
                                <div className="sm:col-span-2">
                                    <SkeletonLoader />
                                </div>
                            </dl>
                        </div>
                    </div>
                ) : (
                    <>
                        {connectionLinks?.find(link => link.type == 'efirma') && (
                            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex gap-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">e.firma</h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Link de subida de e.firma</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <LinkInput link={connectionLinks.find(link => link.type == 'efirma').url} />
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        )}
                        {connectionLinks?.find(link => link.type == 'ciec') && (
                            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex gap-4">
                                    <h3 className="text-lg font-medium leading-6 text-gray-900">CIEC</h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Link de subida de CIEC</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <LinkInput link={connectionLinks.find(link => link.type == 'ciec').url} />
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>
                        )}

                        {lastEfirma && (
                            <div className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex gap-4 justify-between">
                                    <div className='flex gap-4 items-center'>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">e.firma</h3>
                                        <Badge text={lastEfirma?.status} />
                                    </div>
                                    <MenuButton
                                        items={efirmaActions}>
                                        <EllipsisVerticalIcon className="w-6 h-6 text-gray-900" />
                                    </MenuButton>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {lastEfirma?.credentials?.rfc}
                                            </dd>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fecha de subida</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {formatDate(lastEfirma?.created, 'DD MMMM YYYY, HH:mm:ss')}
                                            </dd>
                                        </div>

                                        {lastEfirma?.credentials?.expire_date && (
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Fecha de expiración</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {formatDate(lastEfirma?.credentials?.expire_date, 'DD MMMM YYYY')}
                                                </dd>
                                            </div>
                                        )}

                                        <PermissionsGate allowedRoles={allowedRolesToPassword}>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Contraseña
                                                    <span className='text-xs text-v2-blue-text-login ml-2 cursor-pointer hidden'>
                                                        Copiar
                                                    </span>
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 hidden">
                                                    {lastEfirma?.credentials?.password?.replaceAll(/[a-zA-Z0-9\W|_]/g, "*")}
                                                </dd>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="flex rounded-md shadow-sm">
                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            <input
                                                                type="password"
                                                                value={lastEfirma?.credentials?.password}
                                                                className={`transition-all block w-full rounded-none rounded-l-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login sm:text-sm`}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => copyEfirmaPassword(lastEfirma?.credentials?.password)}
                                                            className={`${efirmaPasswordCopied ? 'bg-green-500 border-green-500' : 'bg-v2-blue-text-login border-v2-blue-text-login'} transition-all relative w-14 -ml-px inline-flex items-center justify-center space-x-2 rounded-r-md border px-4 py-2 text-sm font-medium text-white`}>
                                                            <div className='relative w-full h-full'>
                                                                <ClipboardDocumentIcon className={`${!efirmaPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                                                <ClipboardDocumentCheckIcon className={`${efirmaPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </dd>
                                            </div>

                                            {lastEfirma?.credentials?.cer && lastEfirma?.credentials?.key && (
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">Certificados</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <DocumentList
                                                            documents={["cer", "key"].map(key => {
                                                                const document = lastEfirma?.credentials[key];
                                                                const ext = document.object_name?.split(".")?.pop();
                                                                const documentName = `${lastEfirma?.credentials?.rfc}.${ext}`;
                                                                return {
                                                                    bucket: document.bucket,
                                                                    key: document.object_name,
                                                                    name: documentName
                                                                }
                                                            })}
                                                            onError={downloadError} />
                                                    </dd>
                                                </div>
                                            )}

                                            {lastEfirma?.credentials?.fiel && (
                                                <div className="sm:col-span-2">
                                                    <dt className="text-sm font-medium text-gray-500">Certificados</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <DocumentList
                                                            documents={[
                                                                {
                                                                    bucket: s3Bucket,
                                                                    key: lastEfirma?.credentials?.fiel
                                                                }
                                                            ]}
                                                            onError={downloadError} />
                                                    </dd>
                                                </div>
                                            )}
                                        </PermissionsGate>
                                    </dl>
                                </div>
                            </div>
                        )}

                        {lastCiec && (
                            <div key={lastCiec?.id} className="overflow-hidden bg-white shadow rounded-lg border border-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex gap-4 justify-between">
                                    <div className='flex gap-4 items-center'>
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">CIEC</h3>
                                        <Badge text={lastCiec?.status} />
                                    </div>
                                    <MenuButton
                                        items={ciecActions}>
                                        <EllipsisVerticalIcon className="min-w-[1.25rem] w-6 h-6 text-gray-900" />
                                    </MenuButton>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">RFC</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {lastCiec?.credentials?.rfc}
                                            </dd>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fecha de subida</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {formatDate(lastCiec?.created, 'DD MMMM YYYY, HH:mm:ss')}
                                            </dd>
                                        </div>

                                        <PermissionsGate allowedRoles={allowedRolesToPassword}>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    Contraseña
                                                    <span className='text-xs text-v2-blue-text-login ml-2 cursor-pointer hidden'>
                                                        Copiar
                                                    </span>
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 hidden">
                                                    {lastCiec?.credentials?.password?.replaceAll(/[a-zA-Z0-9\W|_]/g, "*")}
                                                </dd>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <div className="flex rounded-md shadow-sm">
                                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                                            <input
                                                                type="password"
                                                                value={lastCiec?.credentials?.password}
                                                                className={`transition-all block w-full rounded-none rounded-l-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login sm:text-sm`}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={() => copyCiecPassword(lastCiec?.credentials?.password)}
                                                            className={`${ciecPasswordCopied ? 'bg-green-500 border-green-500' : 'bg-v2-blue-text-login border-v2-blue-text-login'} transition-all relative w-14 -ml-px inline-flex items-center justify-center space-x-2 rounded-r-md border px-4 py-2 text-sm font-medium text-white`}>
                                                            <div className='relative w-full h-full'>
                                                                <ClipboardDocumentIcon className={`${!ciecPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                                                <ClipboardDocumentCheckIcon className={`${ciecPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                                            </div>
                                                        </button>
                                                    </div>
                                                </dd>
                                            </div>
                                        </PermissionsGate>
                                    </dl>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </section>

            <SlideOver open={showEfirmaHistory} setOpen={setShowEfirmaHistory} title='e.firma'>
                <div className="-mx-4 sm:-mx-6 overflow-hidden bg-white">
                    <p className="mx-4 sm:mx-6 truncate font-medium text-gray-500 mb-4">Historial de accesos</p>
                    <ul role="list" className="border-t border-b border-200 divide-y divide-gray-200 mb-20">
                        {connections?.filter(connection => connection?.credentials?.type == 'efirma')?.map((connection) => (
                            <li key={connection.id}>
                                <a className="block hover:bg-gray-50 cursor-pointer" onClick={() => setConnectionDetails(connection)}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="flex min-w-0 flex-1 items-center">
                                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-3 md:gap-4">
                                                <div className='col-span-2'>
                                                    <p className="truncate text-sm font-medium">ID: {connection?.fiscal_id}</p>
                                                    <p className="text-sm text-gray-900">{formatDate(connection?.created, 'DD MMMM YYYY, HH:mm:ss')}</p>
                                                </div>
                                                <div className='flex items-center md:justify-end mt-2 md:mt-0'>
                                                    <Badge text={connection.status} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </SlideOver>

            <SlideOver open={showCiecHistory} setOpen={setShowCiecHistory} title='CIEC'>
                <div className="-mx-4 sm:-mx-6 overflow-hidden bg-white">
                    <p className="mx-4 sm:mx-6 truncate font-medium text-gray-500 mb-4">Historial de accesos</p>
                    <ul role="list" className="border-t border-b border-200 divide-y divide-gray-200 mb-20">
                        {connections?.filter(connection => connection?.credentials?.type == 'ciec')?.map((connection) => (
                            <li key={connection.id}>
                                <a className="block hover:bg-gray-50 cursor-pointer" onClick={() => setConnectionDetails(connection)}>
                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                        <div className="flex min-w-0 flex-1 items-center">
                                            <div className="min-w-0 flex-1 pr-4 md:grid md:grid-cols-3 md:gap-4">
                                                <div className='col-span-2'>
                                                    <p className="truncate text-sm font-medium">ID: {connection?.fiscal_id}</p>
                                                    <p className="text-sm text-gray-900">{formatDate(connection?.created, 'DD MMMM YYYY, HH:mm:ss')}</p>
                                                </div>
                                                <div className='flex items-center md:justify-end mt-2 md:mt-0'>
                                                    <Badge text={connection.status} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </SlideOver>

            <SlideOver open={connectionDetails != null} setOpen={() => setConnectionDetails(null)} title='Detalles'>
                <div className="pb-20">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">RFC</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {connectionDetails?.credentials?.rfc}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Estatus</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                <Badge text={connectionDetails?.status} />
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Fecha de subida</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                {formatDate(connectionDetails?.created, 'DD MMMM YYYY, HH:mm:ss')}
                            </dd>
                        </div>

                        {connectionDetails?.credentials?.expire_date && (
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fecha de expiración</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                    {formatDate(connectionDetails?.credentials?.expire_date, 'DD MMMM YYYY')}
                                </dd>
                            </div>
                        )}

                        <PermissionsGate allowedRoles={allowedRolesToPassword}>
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">
                                    Contraseña
                                    <span className='text-xs text-v2-blue-text-login ml-2 cursor-pointer hidden'>
                                        Copiar
                                    </span>
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 hidden">
                                    {connectionDetails?.credentials?.password?.replaceAll(/[a-zA-Z0-9\W|_]/g, "*")}
                                </dd>
                                <dd className="mt-1 text-sm text-gray-900">
                                    <div className="flex rounded-md shadow-sm">
                                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                            <input
                                                type="password"
                                                value={connectionDetails?.credentials?.password}
                                                className={`transition-all block w-full rounded-none rounded-l-md border-gray-300 focus:border-v2-blue-text-login focus:ring-v2-blue-text-login sm:text-sm`}
                                                readOnly
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => copyEfirmaPassword(connectionDetails?.credentials?.password)}
                                            className={`${efirmaPasswordCopied ? 'bg-green-500 border-green-500' : 'bg-v2-blue-text-login border-v2-blue-text-login'} transition-all relative w-14 -ml-px inline-flex items-center justify-center space-x-2 rounded-r-md border px-4 py-2 text-sm font-medium text-white`}>
                                            <div className='relative w-full h-full'>
                                                <ClipboardDocumentIcon className={`${!efirmaPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                                <ClipboardDocumentCheckIcon className={`${efirmaPasswordCopied ? 'opacity-100' : 'opacity-0'} inset-0 absolute transition-all h-5 w-5 text-white`} aria-hidden="true" />
                                            </div>
                                        </button>
                                    </div>
                                </dd>
                            </div>

                            {connectionDetails?.credentials?.cer && connectionDetails?.credentials?.key && (
                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">Cetificados</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <DocumentList
                                            documents={["cer", "key"].map(key => {
                                                const document = connectionDetails?.credentials[key];
                                                const ext = document.object_name?.split(".")?.pop();
                                                const documentName = `${connectionDetails?.credentials?.rfc}.${ext}`;
                                                return {
                                                    bucket: document.bucket,
                                                    key: document.object_name,
                                                    name: documentName
                                                }
                                            })}
                                            onError={downloadError} />
                                    </dd>
                                </div>
                            )}
                        </PermissionsGate>
                    </dl>
                </div>
            </SlideOver>

            <Modal show={showLinkModal} setShow={setShowLinkModal} className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center space-y-3">
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500' onClick={() => setShowLinkModal(false)} />
                    </div>
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Link de subida de accesos
                    </h3>
                    <p className="max-w-xl text-sm text-gray-500">Comparte este link al cliente para subir sus accessos</p>
                    <div className="max-w-xl text-sm text-gray-500">
                        <LinkInput link={connectionLink?.url} />
                    </div>
                </div>
            </Modal>

            <Modal show={showInvalidateModal} setShow={setShowInvalidateModal} className='min-w-full sm:min-w-[500px]'>
                <div className="py-2 px-2 text-center space-y-3">
                    <div className='relative'>
                        <XMarkIcon className='absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500' onClick={() => setShowInvalidateModal(false)} />
                    </div>
                    <h3 className="text-lg pt-2 font-medium leading-6 text-gray-900">
                        ¿Estás seguro que desear invalidar este acceso?
                    </h3>
                    <p className="max-w-xl text-sm text-gray-500 pt-2">Ya no podrás utilizar el acceso una vez que sea inválido</p>
                    <div className='w-full flex gap-2 pt-2'>
                        <PrimaryButton onClick={() => setShowInvalidateModal(false)} isTransparent={true} disabled={false} isFullWidth>
                            Cancelar
                        </PrimaryButton>
                        <PrimaryButton onClick={() => setInvaliteConnection()} disabled={false} isFullWidth>
                            Aceptar
                        </PrimaryButton>
                    </div>
                </div>
            </Modal>
        </>
    )
}

Efirma.propTypes = {
    account: PropTypes.object
}

export default Efirma;