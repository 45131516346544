import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CalendarIcon, CheckBadgeIcon, CheckCircleIcon, ClockIcon, ExclamationTriangleIcon, HandRaisedIcon, MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/solid';
import { NoSymbolIcon } from '@heroicons/react/20/solid';
import ReactTooltip from 'react-tooltip';

function CircleIconCredentianls({ item, name }) {
    const status = Array.isArray(item) ? item?.find((elem) => (elem.name == name)).status : "default"
    const [showTooltip, setShowTooltip] = useState(true);

    const itemStatus = (status) => {
        switch (status) {
            case 'active':
                return <div className='flex bg-green-100 w-9 py-1 rounded-10 justify-center'>
                    <CheckCircleIcon className='w-6 h-6 text-green-900' data-for={"item-credential"}
                        data-tip={"Activa"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>

            case 'pending':
                return <div className='flex bg-yellow-100 w-9 py-1 rounded-10 justify-center'>
                    <ClockIcon className='w-6 h-6 text-yellow-800' data-for={"item-credential"}
                        data-tip={"Pendiente"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'invalid':
                return <div className='flex bg-purple-100 w-9 py-1 rounded-10 justify-center'>
                    <HandRaisedIcon className='w-6 h-6 text-purple-800' data-for={"item-credential"}
                        data-tip={"Inválida"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'none':
                return <div className='flex bg-gray-100 w-9 py-1 rounded-10 justify-center'>
                    <NoSymbolIcon className='w-6 h-6 text-gray-800' data-for={"item-credential"}
                        data-tip={`Sin ${name == 'sat_ciec' ? 'CIEC' : (name == 'sat_efirma' ? 'efirma' : (name == 'tax_status' ? 'CSF' : '32D'))}`}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'expired':
                return <div className='flex bg-red-100 w-9 py-1 rounded-10 justify-center'>
                    <CalendarIcon className='w-6 h-6 text-red-800' data-for={"item-credential"}
                        data-tip={"Expirada"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'negative':
                return <div className='flex bg-red-100 w-9 py-1 rounded-10 justify-center'>
                    <MinusCircleIcon className='w-6 h-6 text-red-800' data-for={"item-credential"}
                        data-tip={"Negativa"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'positive':
                return <div className='flex bg-gre-100 bg-green-100 w-9 py-1 rounded-10 justify-center'>
                    <PlusCircleIcon className='w-6 h-6 text-green-800' data-for={"item-credential"}
                        data-tip={"Positiva"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
            case 'no_obligations':
                return <div className='flex bg-purple-100 w-9 py-1 rounded-10 justify-center'>
                    <CheckBadgeIcon className='w-6 h-6 text-purple-800' data-for={"item-credential"}
                        data-tip={"Sin obligaciones"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>

            case 'invalid_provider':
                return <div className='flex bg-red-100 w-9 py-1 rounded-10 justify-center'>
                    <ExclamationTriangleIcon className='w-6 h-6 text-red-800' data-for={"item-credential"}
                        data-tip={"Inválido por el proveedor"}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>

            default:
                return <div className='flex bg-gray-100 w-9 py-1 rounded-10 justify-center'>
                    <NoSymbolIcon className='w-6 h-6 text-gray-800' data-for={"item-credential"}
                        data-tip={`Sin ${name == 'sat_ciec' ? 'CIEC' : (name == 'sat_efirma' ? 'efirma' : (name == 'tax_status' ? 'CSF' : '32D'))}`}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => {
                            setShowTooltip(false);
                            setTimeout(() => setShowTooltip(true), 50);
                        }} />
                </div>
        }
    }
    return item ? (

        <div className='w-full flex lg:justify-center'>
            {
                showTooltip && <ReactTooltip
                    id="item-credential"
                    place="top"
                    effect='solid' />
            }
            {itemStatus(status)}
        </div>
    ) : <div className='flex bg-gray-100 w-9 py-1 rounded-10 justify-center'>
        <NoSymbolIcon className='w-6 h-6 text-gray-800' />
    </div>
}

CircleIconCredentianls.propTypes = {
    item: PropTypes.any,
    name: PropTypes.string
    // length: PropTypes.number,
}

CircleIconCredentianls.defaultProps = {
    item: {},
    name: ""
    // length: 0,
}

export default CircleIconCredentianls