import React from "react";
import PropTypes from "prop-types";

import { XMarkIcon } from "@heroicons/react/24/solid";

import Modal from "../../tailwindUI/Modal";
import PrimaryButton from "../../tailwindUI/PrimaryButton";
import SecondaryButton from "../../tailwindUI/SecondaryButton";

export function DeleteModal({ showModal, setShowModal, isPending, onDelete }) {
  return (
    <Modal
      show={showModal}
      setShow={setShowModal}
      className="min-w-full sm:min-w-[500px]"
    >
      <div className="py-2 px-2 space-y-3">
        <div className="relative">
          <XMarkIcon
            className="absolute w-7 h-7 -right-3 -top-3 cursor-pointer text-gray-500"
            onClick={() => setShowModal(false)}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <h3 className="text-lg text-center font-medium leading-6 text-gray-900">
            ¿Estás seguro de que deseas eliminar el link corto?
          </h3>
          <div className="w-fit mt-6 ml-auto space-x-2">
            <SecondaryButton
              type="button"
              disabled={isPending}
              onClick={() => setShowModal(false)}
            >
              Cancelar
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={isPending}>
              Confirmar
            </PrimaryButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}

DeleteModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onDelete: PropTypes.func,
  isPending: PropTypes.bool,
};
